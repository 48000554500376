body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.singlePost {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 0;
}

header {
  background: linear-gradient(to bottom, #80b3d8, #bcd7eb, #e9f2f8, #ebf4f9, #ffffff);
  width: 100%;
  padding: 20px 0;
}

.singlePostTitle {
  padding-top: 15%;
  padding-bottom: 5%;
  font-size: 6rem;
  color: #0096d6;
}

.singlePostDesc {
  padding-bottom: 15%;
  font-size: 1.2em;
  margin: 0 auto;
  max-width: 800px;
  line-height: 1.5;
}

/* Media Queries */
@media (max-width: 768px) {
  .singlePostTitle {
    padding-bottom: 10%;
    font-size: 2em;
  }

  .singlePostDesc {
    font-size: 0.9em;
    padding-bottom: 10%;
  }
}

@media (max-width: 480px) {
  .singlePostTitle {
    padding-bottom:10%;
    font-size: 1.5em;
  }

  .singlePostDesc {
    padding-bottom: 15%;
    font-size: 0.9em;
  }
}
