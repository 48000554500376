.posts {
  flex: 9;
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
  justify-self: center;
  align-items: center;
  justify-content: center;
  padding-left: 7%;
}

.inputConatiner {
  margin: 1%;
  width: 30%;
}

.ContactPosts {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}

.zone {
  justify-self: center;
  align-items: center;
  justify-content: center;
  background-color: #bbd7eb;
  padding-bottom: 20px;
  margin-top: 75px;
}

.contactBg {
  background-color: #bbd7eb;
  padding-top: 8%;
}

.zone .zoneHead {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Ensure it takes full width */
  color: #000000;
  font-weight: bold;
  padding-top: 10%;
}

/* Responsive Styles */

/* Extra small devices (phones, less than 576px) */
@media screen and (max-width: 575.98px) {
  .inputConatiner {
    width: 90%; /* Full width for extra small devices */
  }
  .zone .zoneHead {
    padding-top: 20%;
  }
}

/* Small devices (phones, 576px and up) */
@media screen and (min-width: 576px) and (max-width: 767.98px) {
  .inputConatiner {
    width: 60%; /* Adjust width for small devices */
  }
  .zone .zoneHead {
    padding-top: 15%;
  }
}

/* Medium devices (tablets, 768px and up) */
@media screen and (min-width: 768px) and (max-width: 991.98px) {
  .inputConatiner {
    width: 45%; /* Adjust width for medium devices */
  }
  .zone .posts {
    width: 90%;
  }
  .zone .zoneHead {
    padding-top: 15%;
  }
}

/* Large devices (desktops, 992px and up) */
@media screen and (min-width: 992px) and (max-width: 1199.98px) {
  .inputConatiner {
    width: 35%; /* Adjust width for large devices */
  }
  .zone .posts {
    width: 85%;
  }
  .zone .zoneHead {
    padding-top: 12%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media screen and (min-width: 1200px) {
  .inputConatiner {
    width: 30%; /* Default width for extra large devices */
  }
  .zone .posts {
    width: 80%;
  }
  .zone .zoneHead {
    padding-top: 10%;
  }
}
