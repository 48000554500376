*{
margin: 0;
padding: 0;
font-family: sans-serif;

}


/* video{
  width: 100%;
  height: 100%;
  object-fit: cover;

} */
.container{

  width: 100%;
  height: 100%;

  

}


.wlcElectrical{
  color: #f2f5f7;
  font-size: 70px;
  padding-left: 30%;
}.wlcPlumbing{
  color: #f2f5f7;
  font-size: 70px;
  padding-left: 30%;
}.wlcSanitaryware{
  color: #f2f5f7;
  font-size: 60px;
  padding-left: 15%;
}.wlcPaints
{
  color: #f2f5f7;
  font-size: 70px;

  padding-left: 5%;
}.wlcHardware{
  color: #f2f5f7;
  font-size: 70px;
  padding-left: 10%;
}