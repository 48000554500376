.contact {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-wrap: wrap; /* Add this for wrapping on small screens */
}

.contact .leftSide {
  height: 100%;
  flex: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact .rightSide {
  height: 100%;
  flex: 50%;
  margin: 5%; /* Adjusted for better responsiveness */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact .rightSide h1 {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: 700;
  font-size: 40px; /* Adjusted for better responsiveness */
  margin-left: 0; /* Adjusted for better responsiveness */
}

form {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  padding: 20px; /* Adjusted for better responsiveness */
}

form input {
  height: 40px;
  width: 100%; /* Adjusted for better responsiveness */
  border: none;
  border-bottom: 1px solid #121619;
  color: black;
}

form textarea {
  margin-top: 15px;
  height: 70px;
  width: 100%; /* Adjusted for better responsiveness */
  border: none;
  border-bottom: 1px solid #121619;
  color: black;
}

input::placeholder,
textarea::placeholder {
  font-weight: bold;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
}

form input:focus,
textarea:focus {
  outline: none;
}

form label {
  margin-top: 15px;
  color: grey;
}

form button {
  margin-top: 40px;
  width: 140px;
  height: 40px;
  border: none;
  background-color: #121619;
  color: whitesmoke;
  font-size: 15px;
  cursor: pointer;
}

/* Responsive Code */

@media (max-width: 768px) {
  .contact {
    flex-direction: column;
  }

  .contact .leftSide,
  .contact .rightSide {
    flex: 100%;
    height: auto; /* Adjust height for better responsiveness */
  }

  .contact .rightSide {
    margin: 0; /* Remove margin for small screens */
    padding: 20px; /* Add padding for better spacing */
  }

  .contact .rightSide h1 {
    font-size: 30px; /* Adjust font size for better responsiveness */
    margin-left: 0; /* Center align text for small screens */
  }

  form {
    padding: 20px; /* Adjust padding for better responsiveness */
  }

  form button {
    width: 100%; /* Full width button for better responsiveness */
    margin-top: 20px; /* Adjust margin for better spacing */
  }
}

@media (max-width: 480px) {
  .contact .rightSide h1 {
    font-size: 25px; /* Further adjust font size for very small screens */
  }

  form input,
  form textarea {
    width: 100%; /* Full width inputs for better responsiveness */
  }

  form button {
    width: 100%; /* Full width button for better responsiveness */
    margin-top: 20px; /* Adjust margin for better spacing */
  }
}
