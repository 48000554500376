/* src/Gallery.css */

.gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-top: 10%;
    padding-bottom: 15%;
  }
  
  .gallery-main img {
    width: 500px;
    height: auto;
    margin-top: 90px;
    margin-bottom: 20px;
    border: 2px solid #ccc;
    border-radius: 10px;
  }
  
  .gallery-thumbnails {

    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .gallery-thumbnails img {
    width: 100px;
    height: auto;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.3s, transform 0.3s;
  }
  
  .gallery-thumbnails img:hover {
    opacity: 1;
    transform: scale(1.1);
  }
  
  .gallery-thumbnails img.selected {
    opacity: 1;

    border: 2px solid #007BFF;
    border-radius: 5px;
  }
  