.header {
  display: flex;
  margin-bottom: 10%;
}

.header .radio_Text {
  
  position: "absolute";
  font-size: 80px;
  color: #c1811a;
  font-weight: bold;
  margin-bottom: 10px;
}
.headerTitleSm {
  position: absolute;
  display: flex;
  font-size: 20px;
}

.auto-sliders {
  justify-self: "left";
  align-items: "left";
  left: 0%;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: left;
  justify-items: left;
  text-align: left;
  align-items: left;
}
.header .image {
  height: 100%;  /* Set the height you want */
  width: 100%;  /* Set the width you want */
  object-fit: cover; /* Ensures the image covers the area, use 'contain' if you prefer */
}

@media (max-width: 768px) {
  .header .image {
    padding-top: 10%;
    width: 100%;
    position: "absolute";
  }
  .header .radio_Text {
    margin-top: -220px;
    position: "absolute";
    font-size: 40px;
    color: #c1811a;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .radio_button {
    /* margin-right: 10px;
    */
    margin-right: 40px;
    color: #ede6e6;
  }
}
.sliders {
  width: 100%;

  object-fit: cover;
}
.radio_Text {
  margin-top: -400px;
  position: "absolute";
  font-size: 80px;
  color: #c1811a;
  font-weight: bold;
}
.radio_button {
  /* margin-right: 10px;
    */
  margin-right: 40px;
  color: #ede6e6;
}
.radios {
}
