.about {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.aboutTop {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.aboutBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.column {
  height: 100%;
}

.TtittleAbout {
  padding-top: 40px;
  padding-left: 35%;
}

.details {
  padding: 15%;
  margin-top: 10%;
  line-height: 1.5;
  color: dimgray;
}

.aboutImg {
  margin-top: 25%;
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .row {
    grid-template-columns: 1fr;
  }

  .TtittleAbout {
    padding-left: 0;
    text-align: center;
  }

  .details {
    padding: 10%;
    margin-top: 5%;
    color: dimgray;
  }
}

@media (max-width: 480px) {
  .column .aboutImg {
    height: auto;
    width: 100%;
    margin-top: 50%;
  }

  .details {
    padding: 5%;
    margin-top: 5%;
    color: dimgray;
  }
}
