.logo {
  height: 45%;
  width: 50%;
}

.container {
  flex: 1;
}

.top_container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 100px;
  background-color: white;
  position: sticky;
  top: 0;
  font-family: "Josefin Sans", sans-serif;
}

.top_Left {}

.topCenter_ {
  margin: 10px;
}

.topRight {
  flex-direction: row;
}

.logo {
  width: 50px;
  height: 50px;
}

.topList {
  /* flex-direction: row;
  align-items: center; */
}

.link {
  margin-right: 20px;
  font-size: 16px;
  color: #333333;
  text-decoration: underline;
}

.icon {
  margin-right: 10px;
  font-size: 24px;
  color: #333333;
}

.brands {
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
}

.brandCard {
  width: 80%;
  aspect-ratio: 4/3;
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}
