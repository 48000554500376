/* Topbar.css */

/* Default size for large screens */
.logos {
  max-width: 380px; /* Default size */
}

/* Medium screens (tablets) */
@media (max-width: 992px) {
  .logos {
    max-width: 60px; /* Slightly smaller for medium screens */
  }
}

/* Small screens (mobile phones) */
@media (max-width: 576px) {
  .logos {
    max-width: 100px; /* Smaller for small screens */
  }
}
